<form [formGroup]="itemForm" (ngSubmit)="submit()" autocomplete="new-portalform">
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{getTitle()}}</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>
  <mat-dialog-content class="flex flex-col">
  <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-3 flex-auto" *ngIf="isEdit">
        <mat-label>Purchase Order Number</mat-label>
        <input matInput readonly autocomplete="new-purchase-order-number" name="new-purchase-order-number"
          [formControl]="itemForm.controls['purchase_order_number']" placeholder="Purchase Order Number">
      </mat-form-field>

      <mat-form-field class="sm:mt-3 flex-auto" [ngClass]="isEdit ? 'sm:ml-6' : ''" *ngIf="!showWholesale">
        <mat-label>Quote</mat-label>
        <input readonly matInput autocomplete="new-quote" name="new-quote"
            [formControl]="itemForm.controls['quote_id']" placeholder="Quote">
      </mat-form-field>

      <mat-form-field class="sm:mt-3 flex-auto" [ngClass]="isEdit ? 'sm:ml-6' : ''" *ngIf="showWholesale">
        <mat-label>Wholesale Quote</mat-label>
        <input readonly matInput autocomplete="new-wholesale-quote" name="new-wholesale-quote"
            [formControl]="itemForm.controls['wholesale_id']" placeholder="Wholesale Quote">
      </mat-form-field>
  </div>
    
    <div class="flex flex-col sm:flex-row">
      <div class="flex-auto m-flex">
        <mat-form-field>
          <mat-label>Vendor</mat-label>
          <mat-select [formControl]="itemForm.controls['vendor_id']" placeholder="Vendor" #vendorSelect
            (selectionChange)="onVendorFilterChange($event.value)"
            (infiniteScroll)="getNextBatchOfVendors()"[threshold]="'0.5%'" [debounceTime]="150" [complete]="vendorsOffset === totalVendorsPages" msInfiniteScroll>
            <mat-option>
            <ngx-mat-select-search *ngIf="true" [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'No matches' "
              [formControl]="vendorFilterCtrl" [preventHomeEndKeyPropagation]="true">
              <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let vendor of filteredVendor" [value]="vendor.id">
              {{ vendor?.name }}
            </mat-option>
            <mat-option *ngIf="loadingVendors">
              <mat-spinner class="input-mat-spinner-loader"
              color="primary"
              [diameter]="30"></mat-spinner>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="sm:ml-6 mt-2">
          <button mat-raised-button class="float-right mb-15" color="primary" type="button" (click)="addNewVendor()">Add
            New</button>
        </div>
      </div>

      <div class="sm:ml-6 flex-auto m-flex" *ngIf="filteredVendor && filteredVendor.length && vendorId">
        <mat-form-field>
          <mat-label>Vendor Contact</mat-label>
          <mat-select [formControl]="itemForm.controls['vendor_contact_id']" placeholder="Vendor Contact"
            #vendorContactSelect (selectionChange)="onVendorContactFilterChange($event.value)">
            <mat-option>
            <ngx-mat-select-search *ngIf="true" [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'No matches' "
              [formControl]="vendorContactFilterCtrl" [preventHomeEndKeyPropagation]="true">
              <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let vendorContact of filteredVendorContact" [value]="vendorContact.id">
              {{ vendorContact?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="sm:ml-6 mt-2">
          <button mat-raised-button class="float-right mb-15" color="primary" type="button" (click)="addNewVendorContact()">Add
            New</button>
        </div>
      </div>
    </div>

    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Description</mat-label>
        <input matInput type="description" autocomplete="new-description" name="new-description"
          [formControl]="itemForm.controls['description']" placeholder="Description">
      </mat-form-field>

      <mat-form-field class="sm:ml-6 flex-auto">
        <mat-label>Amount</mat-label>
        <input matInput autocomplete="new-amount" name="new-amount" [formControl]="itemForm.controls['amount']"
          mask="separator.2" placeholder="Amount" thousandSeparator="," [allowNegativeNumbers]="true">
        <span matPrefix>$&nbsp;</span>
      </mat-form-field>
    </div>

    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Deal Approval</mat-label>
        <mat-select [formControl]="itemForm.controls['request_approval_from']" placeholder="Deal Approval"
            #singleSelect (selectionChange)="onRequestApprovalFilterChange($event.value)">
            <mat-option>
            <ngx-mat-select-search *ngIf="true" [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'No matches' "
              [formControl]="requestApprovalFilterCtrl" [preventHomeEndKeyPropagation]="true">
              <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
            <mat-option *ngFor="let requestApproval of filteredRequestApproval" [value]="requestApproval.id">
              {{ requestApproval?.first_name }}
            </mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field class="sm:ml-6 flex-auto">
        <mat-label>Category</mat-label>
        <mat-select [formControl]="itemForm.controls['category']" placeholder="Category " #singleSelect>
          <mat-option value="customer">Customer </mat-option>
          <mat-option value="office">Office</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Payment Date</mat-label>
        <input autocomplete="off" matInput name="payment_date" [matDatepicker]="payment_date"
            placeholder="Payment Date" [formControl]="itemForm.controls['payment_date']">
          <mat-datepicker-toggle matSuffix [for]="payment_date"></mat-datepicker-toggle>
          <mat-datepicker #payment_date></mat-datepicker>
      </mat-form-field>

        <div class="sm:ml-6 flex-auto" *ngIf="isAllowUpdate">
          <mat-checkbox color="primary" name="approve" [formControl]="itemForm.controls['approved']" class="pb-1">Approve
          </mat-checkbox>
        </div>
    </div>

        <mat-dialog-actions align="end">
          <div>
          <button mat-button mat-dialog-close type="button" (click)="dialogRef.close(false)">Cancel</button>
          <app-button-loading loadingText="Processing..." [loading]="saving" [disabled]="itemForm.invalid || itemForm.pristine" color="primary">{{ saveButtonLabel | translate }}</app-button-loading>
          <span fxFlex></span>
          </div>
        </mat-dialog-actions>
  </mat-dialog-content>
</form>