<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">Reset Required Alert</h2>
</div>

<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-content class="flex flex-col mt-3">
  <p>Please reset your password by clicking OK</p>

  <mat-dialog-actions align="end" class="mt-3">
      <button type="button" color="primary" mat-flat-button (click)="resetRequired()">OK</button>
  </mat-dialog-actions>
</mat-dialog-content>
