<form [formGroup]="itemForm" (ngSubmit)="submit()" autocomplete="new-userform">
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{data.title}}</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>
  <mat-dialog-content class="flex flex-col">
  <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-3 flex-auto">
        <mat-label>Name</mat-label>
        <input
        matInput
        autocomplete="new-name"
        name="new-name"
        [formControl]="itemForm.controls['name']"
        placeholder="Name"
        appNoWhitespace>
        <mat-icon class="mr-3" matPrefix svgIcon="mat:person"></mat-icon>
      </mat-form-field>
      <mat-form-field class="sm:mt-3 sm:ml-6 flex-auto">
        <mat-label>Street</mat-label>
        <input
        matInput
        autocomplete="new-street"
        name="new-street"
        [formControl]="itemForm.controls['street']"
        placeholder="Street Address"
        appNoWhitespace>
        <mat-icon class="mr-3" matPrefix svgIcon="mat:edit_location"></mat-icon>
      </mat-form-field>
    </div>
    
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>City</mat-label>
        <input
        matInput
        autocomplete="new-city"
        name="new-city"
        [formControl]="itemForm.controls['city']"
        placeholder="City"
        appNoWhitespace>
        <mat-icon class="mr-3" matPrefix svgIcon="mat:location_city"></mat-icon>
      </mat-form-field>

      <mat-form-field class="sm:ml-6 flex-auto">
        <mat-label>State</mat-label>
          <input
          matInput
          autocomplete="new-state"
          name="new-state"
          [formControl]="itemForm.controls['state']"
          placeholder="State"
          appNoWhitespace>
          <mat-icon class="mr-3" matPrefix svgIcon="mat:location_on"></mat-icon>
        </mat-form-field>
  </div>

      <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Zip Code</mat-label>
        <input
        matInput
        autocomplete="new-zipcode"
        name="new-zipcode"
        [formControl]="itemForm.controls['zip_code']"
        placeholder="ZipCode"
        appNoWhitespace>
        <mat-icon class="mr-3" matPrefix svgIcon="mat:my_location"></mat-icon>
      </mat-form-field>

        <mat-form-field class="sm:ml-6 flex-auto w-245">
          <mat-label>Phone</mat-label>
          <input
            matInput
            autocomplete="new-phone"
            name="new-phone"
            pattern="^\d{3}[- ]?\d{3}[- ]?\d{4}$"
            [formControl]="itemForm.controls['phone']"
            placeholder="Phone"
            appPhoneNumber>
            <mat-icon class="mr-3" matPrefix svgIcon="mat:phone"></mat-icon>
            <span matPrefix>+1</span>
          </mat-form-field>
        </div>
        
    <div class="flex flex-col sm:flex-row">
        <mat-form-field class="flex-auto">
          <mat-label>Website</mat-label>
          <input
            matInput
            autocomplete="new-website"
            name="new-website"
            [formControl]="itemForm.controls['website']"
            placeholder="Website"
            appNoWhitespace>
            <mat-icon class="mr-3" matPrefix svgIcon="mat:language"></mat-icon>
          </mat-form-field>
      </div> 
        <mat-dialog-actions align="end">
          <div>
          <button mat-button mat-dialog-close type="button" (click)="dialogRef.close(false)">Cancel</button>
          <app-button-loading loadingText="Processing..." [loading]="saving" [disabled]="itemForm.invalid || itemForm.pristine" color="primary">{{ saveButtonLabel | translate }}</app-button-loading>
          <span fxFlex></span>
          </div>
        </mat-dialog-actions>
  </mat-dialog-content>
</form>