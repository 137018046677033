import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
})
export class PrintLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
