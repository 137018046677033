import * as commonModels from 'app/shared/models/common.model';
import * as inventoryModels from 'app/shared/models/inventory.model';

export interface InventoriesState {
  fetching: boolean;
  didFetch: boolean;
  processing: boolean;
  data: Array<inventoryModels.Inventory>;
  filter: inventoryModels.Filter;
  meta: commonModels.Meta;
}

export const initialState: InventoriesState = {
  fetching: false,
  didFetch: false,
  processing: false,
  data: [],
  filter: {
    search: '',
    filter: {
      dealer: null,
      year: null,
      make: null,
      model: null,
      trim: null,
    },
    order_by: 'created_at',
    order_dir: 'desc',
    page: 1,
    per_page: 20,
  },
  meta: {
    current_page: 1,
    from: 1,
    last_page: 0,
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  },
};
