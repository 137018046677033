<ng-template #sidenavRef class="ismobile">
  <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <vex-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
               [mobileQuery]="!(isDesktop$ | async)"
               *ngIf="!isDesktop"
               class="vex-toolbar"></vex-toolbar>
</ng-template>

<ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template>

<vex-layout [quickpanelRef]="quickpanelRef" [sidenavRef]="sidenavRef" [toolbarRef]="toolbarRef"></vex-layout>

<vex-config-panel-toggle></vex-config-panel-toggle>